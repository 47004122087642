
/*nav*/
.navbar {
    z-index: 10;
    top: 0;
    left: 0;
    padding: 10px;
    margin-top: 22rem;
    margin-left: 1rem;
  }

  Link:hover{
    color: #FFF200;
  }
  
  .nav-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    margin-left: 2rem;
  }
  
  .nav-item {
    margin-left: 10px;
    color: #ffffff;
  }
  
  .nav-item:first-child {
    margin-left: 0;
  }
  
  .nav-item a {
    font-size: 1.5rem;
    text-decoration: none;
    color: #ffffff;
  }
  
  .nav-item:nth-child(even) a {
    color: #ffffff; /* Cambia il colore del testo per le voci alternative */
  }
  
  .nav-item:not(:first-child) {
    padding-left: 10px;
    border-left: 1px solid white; /* Aggiungi una barra verticale per le voci alternative */
  }
  
  .slideshow-container {
    position: relative;
    width: 100%;
    height: 70vh;
    background-color: #000000;
    color: aliceblue; /*aliceblue*/
  }
  
  .slide {
    position: absolute;
    transition: transform 0.5s ease, opacity 0.5s ease;
    width: 100%;
  }
  
  .slide-content {
    width: 50%;
    float: right; /* Allinea il contenuto della slide verso destra */
    height: 70vh;
    padding: 20px;
    text-align: right;
    margin-right: 3%;
  }
  
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
  }
  
  .card {
    width: 200rem;
    margin-top: 3rem;
    background: rgba( 255, 255, 255, 0.15 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 18px );
    -webkit-backdrop-filter: blur( 18px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    border-radius: 1rem;
    padding: 1.5rem;
    z-index: 10;
    color: whitesmoke;
  }
  
  
  .text_div{
    margin-top: 10rem;
    margin-right: 3rem;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');
  .slide-content h1 {
    font-size: 5vw;
  
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@500&display=swap');
   h3 {
    font-size: 2rem;
    margin-top: 2.5rem;
    margin-left: 1.3rem;
    font-family: "Oxanium", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
    
  }
  .glass-container h3{
    color: #ffffff;
    text-shadow: 4px 5px 8px rgb(0, 0, 0) inset;
  }
  
  .subtitle{
    font-size: 2rem;
    margin-top: -1rem;
    margin-left: 1.3rem;
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  @import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@1,200&display=swap');
   p {
    font-size: 16px;
    margin-top: 0.2rem;
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .slide-content button {
    z-index: 9;
    margin-top: 4rem;
    width: 8rem;
    height: 2rem;
    background-color: rgb(240, 248, 255);
    border-radius: 0.4rem;
    border-color: aliceblue;
    border: none; 
  }
  
  .slide-content button:hover {
    background-color: rgb(231, 231, 231);
  }
  
  
  
  @import url('https://unpkg.com/css.gg@2.0.0/icons/css/chevron-right.css');
  .info_container {
    width: 47%;
    float: left; /* Allinea l'immagine verso sinistra */
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .info_container img {
    
    width: 60vh;
    height: 20vh;
    margin: -3rem;
    margin-left: 1.5rem;
    margin-top: -81rem;
    
  }
  
  .pagination {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .pagination .dot {
    margin: 5px 0;
    margin-left: 70%;
  }
  
  .dot {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 5px 0;
    background-color: #797979;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: aliceblue;
  }
  /*Nav*/
  
  
  
  /*card header*/
  .glass-container {
    display: flex;
    flex-direction: column; /* Centra verticalmente */
    margin: 2rem;
    align-items: left; /* Allinea i figli al centro rispetto all'asse orizzontale */
    justify-content: left;
    position: absolute;
    top: calc(330% + 3rem);
    
  }
  
  
  @media screen and (max-width: 550px) {
    .glass-container{
      top: calc(20% + 3rem);
    }
    .slideshow-container {
      height: 32rem;
      
    }
  
    .slide-content {
      width: 100%;
      float: none;
      height: auto;
      padding: 1.5rem;
      text-align: center;
      margin-top: 11rem;
    }
  
    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      overflow: hidden;
      border-radius: 1rem;
    }
  
    .card {
      width: 100%;
      height: 15rem;
      margin-top: 1rem;
      padding: 1.5rem;
      background: rgba(255, 255, 255, 0.15);
      box-shadow: 0 0.8rem 2.2rem 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(1.8rem);
      -webkit-backdrop-filter: blur(1.8rem);
      border: 0.1rem solid rgba(255, 255, 255, 0.18);
      border-radius: 1rem;
      color: whitesmoke;
    }
  
    .text_div {
      margin-top: 2rem;
      margin-right: 0;
    }
  
    .slide-content h1 {
      font-size: 8vw;
      margin-bottom: auto;
    }
  
    h3 {
      font-size: 6vw;
      margin-top: -0.2rem;
      margin-left: -1rem;
    }
  
    .slide-content h3 {
      font-size: 1.5rem;
      text-align: center;
      margin-top: auto;
      margin-left: 0rem;
    }
  
    p {
      font-size: 1rem;
      margin-top: -0.5rem;
    }
  
    .slide-content button {
      margin-top: 2rem;
      width: 5rem;
      height: 1.5rem;
      font-size: 0.7rem;
    }
  
    .info_container {
      width: 100%;
      height: 5vh;
      float: none;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  
    .info_container img {
      width: 80%;
      height: auto;
      margin: 0 auto;
      margin-top: -4rem;
    }
  
    .pagination {
      width: 6%;
      margin-top: 13rem;
  
    }
  
    .pagination .dot {
      margin: 0.5rem;
    }
  
    .navbar {
      padding: 0.1rem;
      width: 100%;
      margin-top: 20rem;
      margin-left: -0.1rem;
      align-items: center;
      justify-content: center;
      background-color: #000000;
      
    }
  
    .nav-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      align-items: center;
      justify-content: center;
      
    }
  
    .nav-item {
      margin-top: 0;
    }
  
    .nav-item a {
      text-decoration: none;
      color: #ffffff;
      font-size: 0.9rem;
      border-radius: 0.5rem;
      transition: background-color 0.3s ease;
    }
  
  }
  
  @media screen and (min-width: 551px) and (max-width: 1300px) {
    .info_container img {
      width: 100%;
      height: auto;
      margin-top: -100rem;
    }
    h3 {
      font-size: 3vw;
      margin-top: 3rem;
      margin-left: 1.1rem;
    }
    .glass-container{
      top: calc(400% + 3rem);
    }
    .card {
      height: auto;
    }
    .navbar {
      padding: 0.1rem;
      width: 100%;
      margin-top: 26rem;
      margin-left: -0.1rem;
      align-items: center;
      justify-content: center;
      background-color: #000000;
      
    }
  
    .nav-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      align-items: center;
      justify-content: center;
      
    }
  
    .nav-item {
      margin-top: 0;
    }
  
    .nav-item Link {
      text-decoration: none;
      color: white;
      font-size: 0.7rem;
      border-radius: 0.5rem;
      transition: background-color 0.3s ease;
    }
  }
  