.design-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    padding: 70px 0;
  }
  
  .design {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .timeline {
    width: 80%;
    height: auto;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .timeline-content {
    padding: 10px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    transition: 0.4s ease;
    margin: 1rem;
    margin-bottom: 20px;
    border-radius: 6px;
  }
  
  .timeline-component {
    margin: 0px 20px 20px 20px;
  }
  

    .timeline {
      display: grid;
      grid-template-columns: 1fr 3px 1fr;
    }
    .timeline-middle {
      position: relative;
      background-image: linear-gradient(45deg, #FFF200, #FFF200, #000000);
      width: 3px;
      height: 100%;
    }
    .main-middle {
      opacity: 0;
    }
    .timeline-circle {
      position: absolute;
      top: 0;
      left: 50%;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-image: linear-gradient(45deg, #000000, #000000, #000000);
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  