

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
html, body {
  width: 100%;
  overflow-y: auto;
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  color: #000000;
}

.blockquote {
  padding: 60px 80px 40px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.938);
}
.blockquote p {
  font-size: 2rem;
  font-weight: 700px;
  font-family: "Kanit", sans-serif;
  font-style: normal;
}




.text_hr{
  width: 9rem; /* larghezza desiderata */
  height: 4px; /* assicura che la linea non abbia altezza */ /* specifica lo spessore della linea */
  margin: 20px auto 0;
  background-color: #FFF200; 
  border: none;
}
/*card*/
.container_card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-left: 3rem;
  padding-right: 3rem;
}

.card-site {

  width: calc(48% - 20px); 
  height: auto;
  max-height: 25rem;
  margin: 1rem;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.295);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Per gestire lo z-index */
  position: relative; /* Per gestire lo z-index */
}

.card-img {
  width: 100%;
  height: 100%; /* Altezza pari all'altezza della card */
  background-size: cover;
  background-position: center;
  position: relative;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-content {
  margin-top: 10rem;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba( 255, 255, 255, 0.15 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 18px );
    -webkit-backdrop-filter: blur( 18px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
  box-sizing: border-box;
  
}

.logo3{
  width: 5rem;
  margin-left: 10px;
}

.card-content h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
}

.card-content p {
  font-size: 1rem;
  margin-bottom: 20px;
  font-weight: 500px;
  font-family: "Kanit", sans-serif;
  font-style: normal;
}

.card-content button {
  padding: 10px 20px;
  background-color: #FFF200;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: -1rem;
}

.card-content button:hover {
  background-color: #000000;
  color: #FFF200;
}

/*footer*/
footer {
  background-color: #000;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.logo-container {
  width: 12vh;
}

.logo {
  width: 100%;
  height: auto;
}

.content-container {
  display: flex;
}

.box {
  margin-right: 20px;
  padding: 10px;
  border-radius: 5px;
}

.box ul {
  list-style-type: none;
  padding: 0;
}

.footer-text {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}


.box h4 {
  color: #FFF200;
}

.box input{
  border-radius: 0.3rem;
  border: none;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
}

.box button {
  width: 100%;
  background-color: #FFF200;
  color: #000;
  border: none;
  border-radius: 0.3rem;
  height: 1.2rem;
}

/*Navbar globale*/
.navbar2 {
  background-color: #000;
  color: #fff;
  top: 0;
  left: 0;
  width: 100%;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-brand {
  display: inline-block;
}

.logo_navbar2 {
  height: 30px;
}

.navbar-menu2 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-menu2 li  {
  margin-right: 5px;
 
  padding-left: 6px;
}

.navbar-menu2 li:not(:first-child){
  border-left: 1px solid white;
}

.navbar-menu2 li:last-child {
  margin-right: 0;
}

.navbar-menu2 a {
  color: #fff;
  text-decoration: none;
}

.navbar-menu2 a:hover {
  color: #FFF200;
}



@media screen and (max-width: 1200px) {
  .container_card{
    padding: 0;
  }
  .card-site {
    width: calc(100% - 20px);
    height: 25rem;
  }


}


@media screen and (max-width: 550px) {
  .blockquote{
    padding: 30px 40px 20px;
    
  }
  .blockquote p{
    font-size: 1.5rem;
    text-align: left;
  }
  .blockquote:before{
    font-size: 6rem;
    margin-left: -2rem;
  }
  .blockquote::after{
    font-size: 6rem;
    margin-right: 0rem;
  }

  footer {
    flex-direction: column;
    align-items: left;
    text-align: center;
  }
  .logo3{
    width: 5rem;
    height: 5rem;
  }

  .box {
    flex-direction: column;
    flex-basis: calc(50% - 20px);
    margin-right: 0;
    margin-bottom: 20px;
    font-size: 0.7rem;
  }
  /*

  #box4{
    position: relative;
    margin-top: 9rem;
    left: -14rem;
  }*/

  .logo_navbar2{
    width: 4.5rem;
    margin-left: -0.7rem;
  }

  .navbar-menu2 ul{
    float: right;
    

  }
  .navbar-menu2 li{
    font-size: 0.8rem;
    
    align-items: center;
  }

}